@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    font-family: "Lato", sans-serif;
}
.react-datepicker__triangle:before,
.react-datepicker__triangle:after {
    display: none;
}

.react-datepicker {
    border: none !important;
}
